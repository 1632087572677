/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import '@fontsource/raleway'; // Defaults to weight 400 with all styles included.
import '@fontsource/lato'; // Defaults to weight 400 with all styles included.
import '@fontsource/roboto'; // Defaults to weight 400 with all styles included.
import '@fontsource/open-sans'; // Defaults to weight 400 with all styles included.

// You can delete this file if you're not using it
