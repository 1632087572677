// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---gatsby-theme-ghost-attila-src-pages-404-jsx": () => import("./../../../../gatsby-theme-ghost-attila/src/pages/404.jsx" /* webpackChunkName: "component---gatsby-theme-ghost-attila-src-pages-404-jsx" */),
  "component---gatsby-theme-ghost-attila-src-pages-contact-jsx": () => import("./../../../../gatsby-theme-ghost-attila/src/pages/contact.jsx" /* webpackChunkName: "component---gatsby-theme-ghost-attila-src-pages-contact-jsx" */),
  "component---gatsby-theme-ghost-attila-src-pages-offline-jsx": () => import("./../../../../gatsby-theme-ghost-attila/src/pages/offline.jsx" /* webpackChunkName: "component---gatsby-theme-ghost-attila-src-pages-offline-jsx" */),
  "component---gatsby-theme-ghost-attila-src-templates-author-template-jsx": () => import("./../../../../gatsby-theme-ghost-attila/src/templates/authorTemplate.jsx" /* webpackChunkName: "component---gatsby-theme-ghost-attila-src-templates-author-template-jsx" */),
  "component---gatsby-theme-ghost-attila-src-templates-index-template-jsx": () => import("./../../../../gatsby-theme-ghost-attila/src/templates/indexTemplate.jsx" /* webpackChunkName: "component---gatsby-theme-ghost-attila-src-templates-index-template-jsx" */),
  "component---gatsby-theme-ghost-attila-src-templates-post-template-amp-jsx": () => import("./../../../../gatsby-theme-ghost-attila/src/templates/postTemplate.amp.jsx" /* webpackChunkName: "component---gatsby-theme-ghost-attila-src-templates-post-template-amp-jsx" */),
  "component---gatsby-theme-ghost-attila-src-templates-post-template-jsx": () => import("./../../../../gatsby-theme-ghost-attila/src/templates/postTemplate.jsx" /* webpackChunkName: "component---gatsby-theme-ghost-attila-src-templates-post-template-jsx" */),
  "component---gatsby-theme-ghost-attila-src-templates-tags-template-jsx": () => import("./../../../../gatsby-theme-ghost-attila/src/templates/tagsTemplate.jsx" /* webpackChunkName: "component---gatsby-theme-ghost-attila-src-templates-tags-template-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-software-securitizacao-js": () => import("./../../../src/pages/software-securitizacao.js" /* webpackChunkName: "component---src-pages-software-securitizacao-js" */)
}

